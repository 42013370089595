<template>
    <h1>{{ $t('support.title') }}</h1>
    <p>
        {{ $t('support.text')}}
    </p>
    <div class="platforms">
        <a
            class="platform-windows"
            href="https://dwn.intratone.info/teamviewer/TeamViewerQS.exe"
        >
            <i class="fab fa-windows"></i>
            <h5>{{ $t('brands.windows') }}</h5>
        </a>
        <a
            class="platform-apple"
            href="https://dwn.intratone.info/teamviewer/TeamViewerQS.dmg"
        >
            <i class="fab fa-apple"></i>
            <h5>{{ $t('brands.apple') }}</h5>
        </a>
    </div>
    <p class="version">
        {{ version }}
    </p>
</template>

<script>
export default {
    name: "Support",
    data() {
        return {
            version: this.$store.getters['account/getVersion']
        }
    }
}

</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";

h1 {
    font-family: "Avenir Black";
    font-size: 28px;
    padding: 50px;
    text-align: center;
    margin: auto;
    width: 60%;
}

p {
    font-family: "Avenir Black";
    font-size: 14px;
    padding: 20px;
    text-align: center;
    margin: auto;
    width: 60%;
}

.platforms {
    display: flex;
    margin: 50px auto;
    text-align: center;
    @media all and (max-width: 1024px) {
        width: unset;
    }
    @media all and (min-width: 1024px) {
        width: 45%;
    }

    .platform-windows {
        width: 50%;
        color: $blue-lighter;
        transition: color 150ms;
        text-underline-offset: 5px;
        margin-top: 10px;

        i {
            font-size: 100px;
        }

        h5 {
            margin-top: 20px;
        }
    }
    .platform-windows:hover {
        color: $orange-neutral;
        cursor: pointer;
    }
    .platform-apple {
        width: 50%;
        color: $blue-lighter;
        transition: color 150ms;
        text-underline-offset: 5px;

        i {
            font-size: 110px;
        }

        h5 {
            margin-top: 20px;
        }
    }
    .platform-apple:hover {
        color: $orange-neutral;
        cursor: pointer;
    }
}

</style>
